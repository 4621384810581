<template>
  <DxDataGrid
      :data-source="dataSource"
      :ref="gridRefName"
      :height="440"
      :width="500"
      :show-borders="true"
      :column-auto-width="true"
      :allow-column-resizing="true"
      @selection-changed="onSelectionChanged"
  >

    <DxScrolling mode="virtual"/>

    <DxFilterRow :visible="true"/>

    <DxSelection
        show-check-boxes-mode="always"
        mode="multiple"
        select-all-mode="allPages"
    />

    <DxColumn
        data-field="name"
        data-type="string"
        caption="Name"
    />

    <DxColumn
        data-field="hasLogo"
        data-type="boolean"
        caption="Has Logo"
        cell-template="logo-template"
        width="80px"
    />

    <DxToolbar>
      <DxItem
          location="after"
          template="groupingTemplate"
      />

      <DxItem
          location="after"
          template="button"
      />

    </DxToolbar>

    <template #logo-template="{data}">
          <span v-if="data.value === true" class="uk-text-center uk-text-success uk-text-bold"><font-awesome-icon :icon="['fas', 'check']"/></span>
          <span v-if="data.value !== true" class="uk-text-center uk-text-danger uk-text-bold"><font-awesome-icon :icon="['fas', 'times']"/></span>
    </template>

    <template #groupingTemplate>
      <div class="flex-container">
        <DxSelectBox
            width="175"
            placeholder="Select A Tenant"
            :items="tenantsDataSource"
            display-expr="name"
            value-expr="id"
            @value-changed="groupChanged"
        />
      </div>
    </template>

    <template #button>
      <div class="flex-container">
        <DxButton
            :width="50"
            icon="clear"
            hint="Clear Selection"
            type="primary"
            styling-mode="contained"
            @click="clear"
        />
      </div>
    </template>
  </DxDataGrid>
</template>

<script lang="ts">

import {
  DxDataGrid, DxColumn, DxScrolling, DxToolbar, DxItem, DxRowDragging, DxSelection
} from 'devextreme-vue/data-grid';

import {DxSelectBox} from 'devextreme-vue/select-box';
import {defineComponent} from "vue";
import CustomStore from "devextreme/data/custom_store";
import {DxButton} from "devextreme-vue";
import {DxFilterRow} from "devextreme-vue/gantt";
import {TableauGroupInfo} from "@/TableauManagement/Api/Entities/TableauGroupInfo";
import {TenantInfo} from "@/TableauManagement/Api/Entities/TenantInfo";
import {TableauApi} from "@/TableauManagement/Api/TableauApi";



export default defineComponent({
  name: "TemplateWorkbooksGroupGrid",
  components: {
    DxDataGrid,
    DxColumn,
    DxItem,
    DxScrolling,
    DxToolbar,
    DxSelectBox,
    DxSelection,
    DxButton,
    DxFilterRow

  },

  props: {
    selectedRowsWarehouse: new Array<any>(),
  },

  emits: ['saveWarehouses'],

  data: function () {
    return {
      groups: new Array<TableauGroupInfo>(),
      tenantsDataSource: new Array<TenantInfo>(),
      dataSource: undefined as CustomStore | undefined,
      selectedGroupWarehouse: undefined,
      selectedRows: new Array<string>(),
      gridRefName: 'grid',
    };
  },

  mounted: async function () {
    TableauApi.tenantDataSource()
        .then(response => {
          this.tenantsDataSource = response.data;
    });
  },

  computed: {
    grid(): any {
      return this.$refs[this.gridRefName] as typeof DxDataGrid;
    },
  },

  methods: {

    onSelectionChanged(selectedRows: any) {
      this.selectedRows = new Array<any>();

      for (let x = 0; x < selectedRows.selectedRowsData.length; x++) {
        this.selectedRows.push(selectedRows.selectedRowsData[x]);
      }

      this.$emit('saveWarehouses', this.selectedRows);
    },

    clear() {
      this.selectedRows = new Array<any>();
      this.grid.instance.deselectAll();
    },

    groupChanged(e: any) {
      this.selectedGroupWarehouse = e.value;

      TableauApi.warehouseDataSource(this.selectedGroupWarehouse).then(result => {
        this.dataSource = result;
      });
    },

  },

});
</script>

<style scoped lang="scss">

</style>