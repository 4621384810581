<template>
  <DxDataGrid
      :data-source="dataSource"
      :ref="gridRefName"
      :height="440"
      :width="250"
      :show-borders="true"
      :column-auto-width="true"
      :allow-column-resizing="true"
      @selection-changed="onSelectionChanged"
  >

    <DxScrolling mode="virtual"/>

    <DxFilterRow :visible="true"/>

    <DxSelection
        show-check-boxes-mode="always"
        mode="single"
        select-all-mode="allPages"
    />

    <DxColumn
        data-field="name"
        data-type="string"
        caption="Name"
    />

    <DxToolbar>
      <DxItem
          location="after"

      />

      <DxItem
          location="after"
          template="button"
      />

    </DxToolbar>


    <template #button>
      <div class="flex-container">
        <DxButton
            :width="50"
            icon="clear"
            hint="Clear Selection"
            type="primary"
            styling-mode="contained"
            @click="clear"
        />
      </div>
    </template>
  </DxDataGrid>
</template>

<script lang="ts">

import {
  DxDataGrid, DxColumn, DxScrolling, DxToolbar, DxItem, DxRowDragging, DxSelection
} from 'devextreme-vue/data-grid';

import {defineComponent} from "vue";
import CustomStore from "devextreme/data/custom_store";
import {DxButton} from "devextreme-vue";
import {DxFilterRow} from "devextreme-vue/gantt";
import {TableauGroupInfo} from "@/TableauManagement/Api/Entities/TableauGroupInfo";
import {TableauApi} from "@/TableauManagement/Api/TableauApi";


export default defineComponent({
  name: "TableauServerGrid",
  components: {
    DxDataGrid,
    DxColumn,
    DxItem,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxButton,
    DxFilterRow

  },

  props: {
    selectedRowsWarehouse: new Array<any>(),
  },

  emits: ['saveTableauServerId'],

  data: function () {
    return {
      groups: new Array<TableauGroupInfo>(),

      dataSource: undefined as CustomStore | undefined,
      selectedGroupWarehouse: undefined,
      selectedRows: new Array<string>(),
      gridRefName: 'grid',
    };
  },

  mounted: async function () {
    TableauApi.tableauServerDataSource()
        .then(response => {
          this.dataSource = response;
        });
  },

  computed: {
    grid(): any {
      return this.$refs[this.gridRefName] as typeof DxDataGrid;
    },
  },

  methods: {

    onSelectionChanged(selectedRows: any) {
      this.selectedRows = new Array<any>();

      for (let x = 0; x < selectedRows.selectedRowsData.length; x++) {
        this.selectedRows.push(selectedRows.selectedRowsData[x]);
      }

      this.$emit('saveTableauServerId', this.selectedRows);
    },

    clear() {
      this.selectedRows = new Array<any>();
      this.grid.instance.deselectAll();
    },

  },

});
</script>

<style scoped lang="scss">

</style>