import {AxiosResponse} from "axios";
import {AuthPlugin, IAuth} from "@/auth";
import {BaseAuthenticatedApi} from "@/api/BaseAuthenticatedApi";

interface ICoreApi {
    getUserInfo(): Promise<AxiosResponse<UserModel>>
}

class CoreApiImpl extends BaseAuthenticatedApi implements ICoreApi {
    constructor(auth: IAuth) {
        const api_url = process.env.VUE_APP_MANAGEMENT_API;
        super(auth, api_url);
    }

    public async getUserInfo(): Promise<AxiosResponse<UserModel>> {
        const config = await super.getDefaultConfiguration();
        return this.api.get<UserModel>(`/user/info`, config);
    }
}

interface UserModel {
    data: {
        user_id: string;
        username: string;
        name: string;
        is_employee: boolean;
    }
}


const CoreApi = new CoreApiImpl(AuthPlugin) as ICoreApi;

export {
    CoreApi,
    ICoreApi,
    UserModel
};