<template>
  <div>
    <DxDataGrid
        :data-source="dataSource"
        :ref="gridRefName"
        :height="440"
        :width="500"
        :show-borders="true"
        :column-auto-width="true"
        :allow-column-resizing="true"
    >

      <DxScrolling mode="virtual"/>

      <DxColumn
          data-field="warehouseName"
          :width="300"
          data-type="string"
          caption="Warehouse"
      />

      <DxEditing
          :allow-updating="false"
          :allow-adding="false"
          :allow-deleting="true"
          :confirm-delete="false"
          mode="row"/> <!-- 'batch' | 'cell' | 'form' | 'popup' -->

      <DxColumn
          data-field="numberOfWorkbooks"
          data-type="int"
          caption="# Of WBs"
          :width="60"
      />


      <DxToolbar>
        <DxItem
            location="after"
            template="add"
        />

        <DxItem
            location="after"
            template="clear"
        />

      </DxToolbar>

      <template #add>
        <div class="flex-container">
          <DxButton
              :width="170"
              hint="Add Current Selections To Queue"
              text="Add To Queue"
              icon="plus"
              type="primary"
              styling-mode="contained"
              @click="AddToQueue"
              :disabled="this.selectedWarehouses.length <= 0 || this.selectedWorkbooks.length <= 0"
          />
        </div>
      </template>

      <template #clear>
        <div class="flex-container">
          <DxButton
              :width="50"
              icon="clear"
              hint="Clear Selection"
              type="primary"
              styling-mode="contained"
              @click="clear"
          />
        </div>
      </template>

    </DxDataGrid>

    <div class="uk-margin uk-grid-small uk-child-width-auto uk-grid">
      <label><input class="uk-checkbox" type="checkbox" v-model="shouldInstallOnCme"> Install on CME Website</label>
      <div class="uk-text-small uk-text-muted uk-text-light uk-text-italic">NOTE: if the checkbox is checked, then it will not install on solutions.engine.net</div>
    </div>

    <DxButton
        v-show="!startedRunning"
        class="run-button-css"
        :width="120"
        hint="Run Tableau API For Current Queue"
        text="Run"
        icon="video"
        type="default"
        styling-mode="contained"
        @click="RunWorkbooks"
        :disabled="this.dataSource.length <= 0"
    />

    <span v-show="startedRunning" class="spinner"></span>


  </div>
</template>

<script lang="ts">

import {
  DxDataGrid, DxColumn, DxScrolling, DxToolbar, DxItem, DxRowDragging, DxSelection, DxEditing
} from 'devextreme-vue/data-grid';

import {defineComponent} from "vue";

import {DxButton} from "devextreme-vue";
import CustomStore from "devextreme/data/custom_store";
import {Toast, ToastOptions, ToastType} from "@/components/Toast";
import {CurrentQueue, TableauApi} from "@/TableauManagement/Api/TableauApi";



export default defineComponent({
  name: "TemplateWorkbooksGroupGrid",
  components: {
    DxDataGrid,
    DxColumn,
    DxItem,
    DxScrolling,
    DxToolbar,
    DxButton,
    DxEditing
  },

  props: {
    selectedWarehouses: new Array<any>(),
    selectedWorkbooks: new Array<any>(),
    selectedTableauServer:  undefined as any | undefined,
  },

  data: function () {
    return {
      dataSource: new Array<CurrentQueue>(),
      test: undefined as CustomStore | undefined,
      shouldInstallOnCme: false,
      gridRefName: 'grid',
      startedRunning: false,
    };
  },

  computed: {
    grid(): any {
      return this.$refs[this.gridRefName] as typeof DxDataGrid;
    },
  },

  methods: {
    RunWorkbooks() {
      if (this.selectedTableauServer == undefined)
        Toast.show(new ToastOptions('No Tableau Server Was Selected', ToastType.error));

      else {
        this.startedRunning = true;
        TableauApi.runTableauApi(this.dataSource, this.shouldInstallOnCme).then(response => {
          this.startedRunning = false;

          if (response.data)
            Toast.show(new ToastOptions('Check the logs to see queue progress.', ToastType.success));

          if (!response.data)
            Toast.show(new ToastOptions('Something went wrong.', ToastType.error));
        });
      }
    },

    clear() {
      this.dataSource = new Array<CurrentQueue>();
    },

    AddToQueue() {
      let newQueue = {} as CurrentQueue;
      for (let x = 0; x < this.selectedWarehouses.length; x++) {
        newQueue = {} as CurrentQueue;

        newQueue.warehouseId = this.selectedWarehouses[x].id;
        newQueue.warehouseName = this.selectedWarehouses[x].name;
        newQueue.numberOfWorkbooks = this.selectedWorkbooks.length;
        newQueue.tableauServerId = this.selectedTableauServer[0].id;

        let workbook_list = new Array<string>();
        for (let w = 0; w < this.selectedWorkbooks.length; w++) {
          workbook_list.push(this.selectedWorkbooks[w].id);
        }

        newQueue.workbooks = workbook_list;

        this.dataSource.push(newQueue);
      }
      this.grid.instance.refresh();
    },
  },

});
</script>

<style scoped lang="scss">
.run-button-css {
  margin-left: 75%;
  margin-top: 10%;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  margin-left: 15em;
  margin-top: 2em;
}
</style>
