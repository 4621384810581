<template>
  <div class="tables">
    <div>
      <DxList
          class="side-bar-css"
          :width="200"
          selection-mode="single"
          :items="navigation"
          item-template="links"
          @selection-changed="navigate">
        <template #links="{ data }">
          <div>
            <div>
              <div :class="{'dx-list-item-icon-container': true}">
                <i class="dx-icon dx-list-item-icon" :class="'dx-icon-' + data.icon"></i>
              </div>
              <span>{{ data.text }}</span>
            </div>
          </div>
        </template>
      </DxList>
    </div>

    <div style="width:100%; height:100%" v-if="showLogos" class="tables">
      <iframe src="https://mba.dsci.com/apps/37012/" style="width:100%; height:100%"></iframe>
    </div>

    <div v-show="ShowSettings" class="tables uk-align-center">

      <div>
        <div class="title-css">Tableau Servers</div>
          <tableau-server-grid @saveTableauServerId="saveTableauServerId" class="column"></tableau-server-grid>
      </div>

      <div>
        <div class="title-css">Template Workbooks</div>
        <template-workbooks-grid @saveWorkbooks="saveWorkbooks" class="column"></template-workbooks-grid>
      </div>

      <div>
        <div class="title-css">Warehouses</div>
        <TemplateGroupsWarehouseGrid class="column" @saveWarehouses="saveWarehouses"></TemplateGroupsWarehouseGrid>
      </div>


      <div>
        <div class="title-css">Queue</div>
        <CurrentQueue class="column"
                      v-bind:selected-workbooks="selectedWorkbooks"
                      v-bind:selected-warehouses="selectedWarehouses"
                      v-bind:selected-tableau-server="selectedTableauServer"
        >

        </CurrentQueue>
      </div>

    </div>

    <div v-if="showLogs" class="tables uk-align-center">
      <div>
        <div class="title-css">Queue Logs</div>
        <tableau-logs></tableau-logs>
      </div>
    </div>

    <div v-if="showSyncLogs" class="tables uk-align-center">
      <div>
        <div class="title-css">Sync Logs</div>
        <sync-logs></sync-logs>
      </div>
    </div>

  </div>

</template>

<script lang="ts">
import {defineComponent} from "vue";
import TableauServerGrid from "@/TableauManagement/Components/TableauServerGrid.vue";
import TemplateGroupsWarehouseGrid from "@/TableauManagement/Components/TableauWarehouseGrid.vue";
import {DxList} from "devextreme-vue/list";
import CurrentQueue from "@/TableauManagement/Components/CurrentQueue.vue";
import TableauLogs from "@/TableauManagement/Components/TableauLogs.vue";
import SyncLogs from "@/TableauManagement/Components/SyncLogs.vue";
import TemplateWorkbooksGrid from "@/TableauManagement/Components/TemplateWorkbooksGrid.vue";

export default defineComponent({
  name: "App",
  components: {
    TemplateWorkbooksGrid,
    TemplateGroupsWarehouseGrid,
    CurrentQueue,
    DxList,
    TableauLogs,
    SyncLogs,
    TableauServerGrid
  },

  data: function () {
    return {
      selectedWarehouses: new Array<string>(),
      selectedWorkbooks: new Array<string>(),
      selectedTableauServer: '',
      showTemplates: true,
      showLogos: false,
      ShowSettings: false,
      showLogs: false,
      showSyncLogs: false,
      navigation: [
        {id: 3, text: "Setup / Run Tableau API", icon: "video", selected: true},
        {id: 4, text: "Tableau Queue Logs", icon: "folder", selected: true},
        {id: 5, text: "Tableau Sync Logs", icon: "refresh", selected: true},
      ]
    };
  },

  methods: {
    saveWorkbooks(e: any) {
      this.selectedWorkbooks = e;
    },

    saveWarehouses(e: any) {
      this.selectedWarehouses = e;
    },

    saveTableauServerId(e: any) {
      this.selectedTableauServer = e;
    },

    navigate(e: any) {

      if (e.addedItems[0].id === 1) {
        this.showTemplates = true;
        this.showLogos = false;
        this.ShowSettings = false;
        this.showLogs = false;
        this.showSyncLogs = false;
      }

      if (e.addedItems[0].id === 2) {
        this.showTemplates = false;
        this.showLogos = true;
        this.ShowSettings = false;
        this.showLogs = false;
        this.showSyncLogs = false;
      }


      if (e.addedItems[0].id === 3) {
        this.showTemplates = false;
        this.showLogos = false;
        this.ShowSettings = true;
        this.showLogs = false;
        this.showSyncLogs = false;
      }

      if (e.addedItems[0].id === 4) {
        this.showTemplates = false;
        this.showLogos = false;
        this.ShowSettings = false;
        this.showLogs = true;
        this.showSyncLogs = false;
      }

      if (e.addedItems[0].id === 5) {
        this.showTemplates = false;
        this.showLogos = false;
        this.ShowSettings = false;
        this.showLogs = false;
        this.showSyncLogs = true;
      }
    },
  },

});
</script>

<style scoped lang="scss">
.tables {
  display: flex;
  height: 950px;
}

.column:first-child {
  width: 50%;
  padding-right: 15px;
}

.column:last-child {
  width: 50%;
  padding-left: 15px;
}

.column2 {
  font-size: larger;
  margin-top: 200px;
}

.column {

}

.outer {
  margin-top: 50px;
}

.title-css {
  position: absolute;
  z-index: 99999999;
  margin-left: 20px;
  text-decoration: underline;
}

.button-color {
  background: black;
}


.second-row {
  top: 100px
}

.wrapper {
  height: 100%;
  background-color: #CCC;
}

.side-bar-css {
  border-right: 1px solid #e0dcdc;
  height: 100%;
}
</style>
