<template>
  <div ref="loadAdhocDialog" :class="{'uk-modal-container': shouldExpand}" data-uk-modal>
    <div class="uk-modal-dialog">
      <button class="uk-modal-close-default" type="button" data-uk-close></button>
      <div class="uk-modal-header">
        <slot name="title"></slot>
      </div>
      <div class="uk-modal-body">
        <slot></slot>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import UIkit from "uikit";

export default defineComponent({
  name: "DefaultDialog",
  emits: ['update:isVisible', 'onDialogClose', 'onshowDialog'],
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    shouldExpand: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods: {
    visibilityChanged(isVisible: boolean) {
      this.$emit('update:isVisible', isVisible);
    },

    closeDialog(tgt: Event | null = null) {
      if (tgt !== null && tgt.target !== this.$refs.loadAdhocDialog)
        return;

      this.visibilityChanged(false);
      this.$emit('onDialogClose');
    },
    showDialog(tgt: Event | null = null) {
      if (tgt !== null && tgt.target !== this.$refs.loadAdhocDialog)
        return;

      this.$emit('onshowDialog');
    },
  },
  watch: {
    isVisible: function () {
      const loadAdhocDialog = this.$refs.loadAdhocDialog as HTMLDivElement;

      if (this.isVisible) {
        UIkit.modal(loadAdhocDialog).show();
      } else {
        UIkit.modal(loadAdhocDialog).hide();
      }
    },
  },
  mounted() {
    const loadAdhocDialog = this.$refs.loadAdhocDialog as HTMLDivElement;

    loadAdhocDialog.addEventListener('hide', this.closeDialog, undefined);
    loadAdhocDialog.addEventListener('show', this.showDialog, undefined);
  },
  beforeUnmount() {
    const loadAdhocDialog = this.$refs.loadAdhocDialog as HTMLDivElement;

    loadAdhocDialog.removeEventListener('hide', this.closeDialog);
    loadAdhocDialog.addEventListener('show', this.showDialog);
  }
});

</script>