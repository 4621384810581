import {Commit} from 'vuex';
import {CoreApi} from "@/api/CoreApi";
import {IUserMutationPayload} from "@/store/modules/Payloads/GetUserMutationPayload";

export const ModulePath = 'user/';

export interface IUserState {
    user_id: string;
    name: string;
    username: string;
    is_employee: boolean;
}

export enum UserActionTypes {
    SET_USER = 'setUserInfo',
}

export enum UserMutationTypes {
    UPDATE_USER = 'updateUserInfo',
}

export default {
    namespaced: true,
    state: (): IUserState => ({
        user_id: '',
        name: '',
        username: '',
        is_employee: false
    }),
    mutations: {
        [UserMutationTypes.UPDATE_USER]: function (state: IUserState, payload: IUserMutationPayload): void {
            state.name = payload.name;
            state.user_id = payload.user_id;
            state.username = payload.username;
            state.is_employee = payload.is_employee;
        }
    },
    actions: {
        [UserActionTypes.SET_USER]: async function ({commit}: { commit: Commit }): Promise<void> {
            await CoreApi.getUserInfo().then(result => {
                commit({
                    type: UserMutationTypes.UPDATE_USER,
                    user_id: result.data.data.user_id,
                    username: result.data.data.username,
                    name: result.data.data.name,
                    is_employee: result.data.data.is_employee,
                } as IUserMutationPayload);
            });
        }
    }
};