import {IAuth} from "@/auth";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {createStore, Options} from "devextreme-aspnet-data-nojquery";
import CustomStore from "devextreme/data/custom_store";
import {DevExtremeAjaxOptions} from "@/api/Entities/DevExtremeAjaxOptions";
import PivotGridDataSource, {Field} from "devextreme/ui/pivot_grid/data_source";

export interface DownloadResponse {
    wasSuccessful: boolean,
    errorMessage: string
}

export interface devExtremeStoreOptions {
    onBeforeSend?: (method: string, ajaxOptions: DevExtremeAjaxOptions) => void;
    primaryKey?: string
    isUpdateAllowed?: boolean;
    isDeleteAllowed?: boolean;
    isInsertAllowed?: boolean;
    onLoaded?: (result: Array<unknown>) => void;
    params?: Record<string, unknown> | undefined
}

export abstract class BaseAuthenticatedApi {
    protected readonly auth: IAuth;
    protected readonly api: AxiosInstance;
    private readonly baseUrl: string;

    protected constructor(auth: IAuth, baseUrl: string) {
        this.auth = auth;
        this.baseUrl = baseUrl;
        this.api = axios.create({
            baseURL: baseUrl
        });
    }

    protected async getDevExtremePivotStore(urlPart: string, fields: Array<Field>,
                                            onLoaded?: () => void,
                                            onBeforeSend?: (method: string, ajaxOptions: DevExtremeAjaxOptions) => void
    ): Promise<PivotGridDataSource> {
        const accessToken = await this.auth.getTokenSilently();

        return new PivotGridDataSource({
            remoteOperations: true,
            fields: fields as Array<Field>,
            store: createStore({
                loadUrl: this.baseUrl + urlPart,
                onLoaded: onLoaded,
                onBeforeSend: (method, ajaxOptions) => {
                    ajaxOptions.headers = {Authorization: `Bearer ${accessToken}`};

                    if (onBeforeSend !== undefined)
                        onBeforeSend(method, ajaxOptions);
                }

            })
        });
    }

    protected async getDevExtremeStore(urlPart: string, storeOptions: devExtremeStoreOptions): Promise<CustomStore> {
        const accessToken = await this.auth.getTokenSilently();

        const options = {
            loadUrl: this.baseUrl + urlPart,
            onBeforeSend: (method, ajaxOptions) => {
                ajaxOptions.headers = {Authorization: `Bearer ${accessToken}`};

                if (storeOptions.onBeforeSend !== undefined)
                    storeOptions.onBeforeSend(method, ajaxOptions);
            }
        } as Options;

        if (storeOptions.primaryKey !== undefined)
            options.key = storeOptions.primaryKey;

        if (storeOptions.isUpdateAllowed !== undefined)
            options.updateUrl = this.baseUrl + urlPart + "/Update";

        if (storeOptions.isDeleteAllowed !== undefined)
            options.deleteUrl = this.baseUrl + urlPart + "/Delete";

        if (storeOptions.isInsertAllowed !== undefined)
            options.insertUrl = this.baseUrl + urlPart + "/Insert";

        if (storeOptions.params !== undefined)
            options.loadParams = storeOptions.params;

        if (storeOptions.onLoaded !== undefined)
            options.onLoaded = storeOptions.onLoaded;

        return createStore(options);
    }

    // This primarily is used for the bearer token
    protected async getDefaultConfiguration(): Promise<AxiosRequestConfig> {
        const accessToken = await this.auth.getTokenSilently();

        return {
            headers: {Authorization: `Bearer ${accessToken}`},
        };
    }

    protected async DownloadFile(url: string, anonymous = false): Promise<DownloadResponse> {
        let config = {};
        if (anonymous === false) {
            config = await this.getDefaultConfiguration();
        }

        config = Object.assign(config, {responseType: 'blob'});

        let wasSuccess = false;
        let errorMessage = '';

        await this.api.get(url, config).then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');

            let filename = BaseAuthenticatedApi.getFileName(response.headers['content-disposition']);

            if (filename == '') {
                const extension = BaseAuthenticatedApi.getExtensionBasedOnContentType(response.headers['content-type']);

                filename = 'file';

                if (extension != '')
                    filename += `${extension}`;
            }

            fileLink.href = fileURL;
            fileLink.setAttribute('download', filename);
            document.body.appendChild(fileLink);

            fileLink.click();

            document.body.removeChild(fileLink);
            wasSuccess = true;
        }).catch((err) => {
            if (err.response.status == 401) {
                errorMessage = 'You are not authorized to download this file.';
            } else {
                errorMessage = 'Failed to download file. Please contact DSCI if this persists.';
            }

            console.error(err);
        });

        return {
            wasSuccessful: wasSuccess,
            errorMessage: errorMessage
        };
    }

    private static getExtensionBasedOnContentType(contentType: string): string {
        if (contentType == '' || contentType == undefined)
            return '';

        if (contentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
            return '.xlsx';

        if (contentType == 'application/vnd.ms-excel')
            return '.xls';

        if (contentType == 'text/csv')
            return '.csv';

        if (contentType == 'application/zip')
            return '.zip';

        return '';
    }

    private static getFileName(contentDisposition: string): string {
        let filename = "";

        if (contentDisposition == '' || contentDisposition == undefined) {
            return filename;
        }

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename\s*[;=]\s*((['"]).*?\2|[^;]*)/;
            const matches = filenameRegex.exec(contentDisposition);

            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        return filename;
    }
}