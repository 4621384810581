import notify from "devextreme/ui/notify";

interface IToast {
    show(options: IToastOptions): void;
}

class ToastOptions implements IToastOptions {
    message: string;
    closeOnClick?: boolean;
    displayTime?: number;
    position?: PositionTypes;
    type: ToastType;
    width?: number | string;
    wrapperAttributes?: WrapperAttribute;

    constructor(message: string, type: ToastType) {
        this.message = message;
        this.type = type;
    }
}

enum ToastType {
    error = 'error',
    warn = 'warn',
    success = 'success'
}

enum PositionTypes {
    Bottom = 'bottom',
    Center = 'center',
    Left = 'left',
    LeftBottom = 'left bottom',
    LeftTop = 'left top',
    Right = 'right',
    RightBottom = 'right bottom',
    RightTop = 'right top',
    Top = 'top'
}

interface WrapperAttribute {
    id?: string,
    class?: string,
}

interface IToastOptions {
    message: string,
    type: ToastType,
    closeOnClick?: boolean,
    displayTime?: number,
    position?: PositionTypes,
    width?: number | string,
    wrapperAttributes?: WrapperAttribute
}


const Toast: IToast = {
    show(options: IToastOptions) : void {
        let displayTime = options.displayTime ?? 60000;

        if (options.displayTime == undefined) {
            if (options.type == ToastType.success) {
                displayTime = 3000;
            }
        }

        notify({
            message: options.message,
            closeOnClick: options.closeOnClick ?? true,
            position: options.position ?? PositionTypes.Top,
            width: options.width ?? '60vw',
            wrapperAttr: options.wrapperAttributes ?? { class: 'dsci-toast' },
            animation: {
                hide: {type: 'fade', from: 1, to: 0, duration: 300 },
                show: {type: 'fade', from: 0, to: 1, duration: 300 }
            }
        }, options.type, displayTime);
    }
};

export {
    Toast,
    ToastOptions,
    IToastOptions,
    ToastType,
    PositionTypes,
    WrapperAttribute
};