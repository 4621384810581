import {
    GetTokenSilentlyOptions,
    User
} from '@auth0/auth0-spa-js';
import {App, computed, InjectionKey, Plugin, reactive} from 'vue';
import {ComputedRef} from "@vue/reactivity";


interface Auth0PluginState {
    loading: boolean,
    isAuthenticated: boolean;
    user: User | undefined,
    popupOpen: boolean;
    error: any;
}

const state = reactive<Auth0PluginState>({
    loading: true,
    isAuthenticated: false,
    user: {},
    popupOpen: false,
    error: null,
});

function getCookie(cookieName: string)
{
    const cookiesString=RegExp(cookieName+"=[^;]+").exec(document.cookie);
    return decodeURIComponent(!!cookiesString ? cookiesString.toString().replace(/^[^=]+./,"") : "");
}

function getTokenFromCookie(options?: GetTokenSilentlyOptions): Promise<string> {
    const cookie = getCookie('AUTH-TOKEN');
    return new Promise((resolve, reject) => {
        if (cookie) {
            resolve(cookie);
        } else {
            const error = 'Token not found in cookies';
            console.error(error);
            reject(error);
        }
    });}

function getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string> {
    return getTokenFromCookie(o);
}

export const AuthPlugin: IAuth = {
    isAuthenticated: computed(() => state.isAuthenticated),
    loading: computed(() => state.loading),
    user: computed(() => state.user),
    getTokenSilently
};

interface Auth0PluginOptions {
    domain: string,
    clientId: string,
    audience: string,
    redirectUri: string,

}

async function init(options: Auth0PluginOptions): Promise<Plugin> {

    try {
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {

        }
    } catch (e) {
        state.error = e;
    } finally {
        // Initialize our internal authentication state
        state.isAuthenticated = true;
        // state.user = await client.getUser();
        state.loading = false;
    }

    return {
        install: (app: App) => {
            app.provide(AuthKey, AuthPlugin);
        },
    };
}

interface Auth0Plugin {
    init(options: Auth0PluginOptions): Promise<Plugin>;
}


export interface IAuth {
    isAuthenticated: ComputedRef<boolean>,
    loading: ComputedRef<boolean>,
    user: ComputedRef<User | undefined>,
    getTokenSilently: (o?: GetTokenSilentlyOptions) => Promise<string>,
}

export const AuthKey: InjectionKey<IAuth> = Symbol('AuthApi');
export const Auth0: Auth0Plugin = {
    init,
};
