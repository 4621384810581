import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "uk-text-center uk-text-success uk-text-bold"
}
const _hoisted_2 = {
  key: 1,
  class: "uk-text-center uk-text-danger uk-text-bold"
}
const _hoisted_3 = { class: "flex-container" }
const _hoisted_4 = { class: "flex-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxItem = _resolveComponent("DxItem")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createBlock(_component_DxDataGrid, {
    "data-source": _ctx.dataSource,
    ref: _ctx.gridRefName,
    height: 440,
    width: 500,
    "show-borders": true,
    "column-auto-width": true,
    "allow-column-resizing": true,
    onSelectionChanged: _ctx.onSelectionChanged
  }, {
    "logo-template": _withCtx(({data}) => [
      (data.value === true)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'check'] })
          ]))
        : _createCommentVNode("", true),
      (data.value !== true)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'times'] })
          ]))
        : _createCommentVNode("", true)
    ]),
    groupingTemplate: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DxSelectBox, {
          width: "175",
          placeholder: "Select A Tenant",
          items: _ctx.tenantsDataSource,
          "display-expr": "name",
          "value-expr": "id",
          onValueChanged: _ctx.groupChanged
        }, null, 8, ["items", "onValueChanged"])
      ])
    ]),
    button: _withCtx(() => [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_DxButton, {
          width: 50,
          icon: "clear",
          hint: "Clear Selection",
          type: "primary",
          "styling-mode": "contained",
          onClick: _ctx.clear
        }, null, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DxScrolling, { mode: "virtual" }),
      _createVNode(_component_DxFilterRow, { visible: true }),
      _createVNode(_component_DxSelection, {
        "show-check-boxes-mode": "always",
        mode: "multiple",
        "select-all-mode": "allPages"
      }),
      _createVNode(_component_DxColumn, {
        "data-field": "name",
        "data-type": "string",
        caption: "Name"
      }),
      _createVNode(_component_DxColumn, {
        "data-field": "hasLogo",
        "data-type": "boolean",
        caption: "Has Logo",
        "cell-template": "logo-template",
        width: "80px"
      }),
      _createVNode(_component_DxToolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_DxItem, {
            location: "after",
            template: "groupingTemplate"
          }),
          _createVNode(_component_DxItem, {
            location: "after",
            template: "button"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data-source", "onSelectionChanged"]))
}