import {AxiosResponse} from "axios";
import {AuthPlugin, IAuth} from "@/auth";
import CustomStore from "devextreme/data/custom_store";
import {BaseAuthenticatedApi} from "@/api/BaseAuthenticatedApi";
import {TemplateGroups} from "@/TableauManagement/Api/Entities/TemplateGroups";
import {TenantInfo} from "@/TableauManagement/Api/Entities/TenantInfo";

interface ITableauApi {

    getTemplateWorkbooks(): Promise<CustomStore>;

    warehouseDataSource(tenantId?: string): Promise<CustomStore>;

    tenantDataSource(): Promise<AxiosResponse<Array<TenantInfo>>>;

    tableauServerDataSource(): Promise<CustomStore>;

    runTableauApi(queue: Array<CurrentQueue>, shouldInstallOnCme: boolean): Promise<AxiosResponse<boolean>>;

    reRunTableauApi(queueId: string, option: string): Promise<AxiosResponse<boolean>>;

    getTableauLogs(selectedQueue: string): Promise<CustomStore>;

    getTableauQueueLogs(): Promise<CustomStore>;

    checkSyncLogs(): Promise<AxiosResponse<boolean>>;

    syncTableauTemplates(): Promise<AxiosResponse<boolean>>;

    getSyncLogs(): Promise<CustomStore>;

    templateGroupsDataSource(): Promise<AxiosResponse<Array<TemplateGroups>>>;

    selectedGroupWorkbooks(groupId: string): Promise<AxiosResponse<Array<string>>>;

    saveNewTemplateGroup(groupName: string, workbooks: Array<string>): Promise<AxiosResponse<boolean>>;
}

class TableauApiImpl extends BaseAuthenticatedApi implements ITableauApi {
    constructor(auth: IAuth) {
        super(auth, process.env.VUE_APP_CORE_API_HOSTNAME + '/apps/tableau-management');
    }

    public async getTemplateWorkbooks(): Promise<CustomStore> {
        return await this.getDevExtremeStore('/template-workbooks', {
            primaryKey: "id"
        });
    }

    public async getTableauLogs(selectedQueue: string): Promise<CustomStore> {
        return await this.getDevExtremeStore('/tableau-logs/' + selectedQueue, {});
    }

    public async tableauServerDataSource(): Promise<CustomStore> {
        return await this.getDevExtremeStore('/tableau-server', {});
    }

    public async getTableauQueueLogs(): Promise<CustomStore> {
        return await this.getDevExtremeStore('/get-queue-logs', {});
    }

    public async warehouseDataSource(tenantId?: string): Promise<CustomStore> {
        return await this.getDevExtremeStore('/get-warehouses/' + tenantId, {});
    }

    public async tenantDataSource(): Promise<AxiosResponse<Array<TenantInfo>>> {
        const urlPart = '/get-tenants';
        const config = await super.getDefaultConfiguration();

        return this.api.get<Array<TenantInfo>>(urlPart, config);
    }

    public async templateGroupsDataSource(): Promise<AxiosResponse<Array<TemplateGroups>>> {
        const urlPart = '/get-template-groups';
        const config = await super.getDefaultConfiguration();

        return this.api.get<Array<TemplateGroups>>(urlPart, config);
    }

    public async selectedGroupWorkbooks(groupId: string): Promise<AxiosResponse<Array<string>>> {
        const urlPart = '/get-groups-workbooks/' + groupId ;
        const config = await super.getDefaultConfiguration();

        return this.api.get<Array<string>>(urlPart, config);
    }

    public async runTableauApi(queue: Array<CurrentQueue>, shouldInstallOnCme: boolean): Promise<AxiosResponse<boolean>> {
        const config = await super.getDefaultConfiguration();
        const tableauQueue = JSON.stringify({
            'queue': queue,
            'installOnCme': shouldInstallOnCme
        });
        const urlPart = '/run-tableau';
        return this.api.post<boolean>(urlPart, {
            TableauQueues: tableauQueue
        }, config);
    }


    public async saveNewTemplateGroup(groupName: string, workbooks: Array<string>): Promise<AxiosResponse<boolean>> {
        const config = await super.getDefaultConfiguration();

        const templatesObject = {
            groupName: groupName,
            workbooks: workbooks
        };

        const jsonTemplatesObject = JSON.stringify(templatesObject);
        const urlPart = '/save-template-group';

        return this.api.post<boolean>(urlPart, {
            GroupTemplate: jsonTemplatesObject
        }, config);
    }

    public async reRunTableauApi(queueId: string, option: string): Promise<AxiosResponse<boolean>> {
        const config = await super.getDefaultConfiguration();

        let isAllWorkbooks = false;
        if (option === 'All Workbooks')
            isAllWorkbooks = true;

        const urlPart = '/re-run-queue/' + queueId + '/' + isAllWorkbooks;

        return this.api.get<boolean>(urlPart, config);
    }

    public async syncTableauTemplates(): Promise<AxiosResponse<boolean>> {
        const config = await super.getDefaultConfiguration();
        const urlPart = '/sync-tableau-templates';
        return this.api.get<boolean>(urlPart, config);
    }

    public async checkSyncLogs(): Promise<AxiosResponse<boolean>> {
        const config = await super.getDefaultConfiguration();
        const urlPart = '/check-sync-logs';
        return this.api.get<boolean>(urlPart, config);
    }

    public async getSyncLogs(): Promise<CustomStore> {
        return await this.getDevExtremeStore('/get-sync-logs', {});
    }
}

interface CurrentQueueWrapper {
    TableauQueues: Array<CurrentQueue>,
}

interface CurrentQueue {
    warehouseId: string,
    warehouseName: string,
    tableauServerId: any,
    numberOfWorkbooks: number,
    workbooks: Array<string>,
}

const TableauApi: ITableauApi = new TableauApiImpl(AuthPlugin);

export {
    TableauApi,
    ITableauApi,
    TenantInfo,
    CurrentQueue,
    CurrentQueueWrapper
};
