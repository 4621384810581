<template>
  <div v-if="dataSource !== undefined">
    <DxDataGrid
        :data-source="dataSource"
        class="logs uk-align-center"
        :ref="gridRefName"
        :show-borders="true"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        :on-cell-prepared="onCellPrepared"
    >

      <DxScrolling mode="virtual"/>

      <DxColumn
          data-field="isFinished"

          cell-template="status"
          header-cell-template="normal-header"
          caption="Status"
      />

      <DxColumn
          data-field="dateStarted"
          cell-template="dateTemplate"
          header-cell-template="normal-header"
          caption="Date"
      />

      <DxColumn
          data-field="timeStarted"

          cell-template="timeTemplate"
          header-cell-template="normal-header"
          caption="Time Started"
      />

      <DxColumn
          data-field="dateFinished"
          cell-template="timeTemplate"
          header-cell-template="normal-header"
          caption="Time Finished"
      />

      <DxColumn
          data-field="secondsPast"
          header-cell-template="normal-header"
          caption="Time Elapsed"
          cell-template="timeRunning"
      />

      <DxColumn
          data-field="isSuccessful"
          header-cell-template="normal-header"
          caption="Was Successful"
          cell-template="success"
      />

      <DxToolbar>
        <DxItem
            location="after"
            template="refresh"

        />

      </DxToolbar>

      <template #refresh>
        <div class="flex-container">
          <DxButton
              :width="170"
              hint="Refresh"
              text="Refresh"
              icon="refresh"
              type="default"
              styling-mode="contained"
              @click="refreshTable"
          />
        </div>
      </template>

      <template #dateTemplate="{data}">
          <span v-if="data.value !== null" class="uk-align-center uk-text-center uk-text-bold">{{
              formatDate(data.value)
            }}</span>
        <span v-if="data.value === null" class="uk-align-center uk-text-center uk-text-bold">Waiting...</span>
      </template>

      <template #timeTemplate="{data}">
          <span v-if="data.value !== null" class="uk-align-center uk-text-center uk-text-bold">{{
              formatTime(data.value)
            }}</span>

      </template>

      <template #timeRunning="{data}">
          <span v-if="data.data.isFinished !== true" class="uk-align-center uk-text-center uk-text-bold">{{
              formatTimeDifference(data.value)
            }}</span>


        <span  v-if="data.data.isFinished === true" class="uk-align-center uk-text-center uk-text-bold">{{
            formatTimeDifferenceFinished(data.data.timeStarted, data.data.dateFinished)
          }}</span>
      </template>

      <template #normal-header="{data}">
        <span class="uk-text-bold uk-text-center"> {{ data.column.caption }} </span>
      </template>

      <template #status="{data}">
          <span v-if="data.value === false" class="spinner"><span
              class="uk-text-bold running-text">Running</span> </span>
        <span v-if="data.value === null" class="uk-align-center uk-text-center uk-text-warning uk-text-bold"> <font-awesome-icon
            :icon="['fas', 'pause']"/> Queued</span>
        <span v-if="data.value === true" class="uk-align-center uk-text-center uk-text-success uk-text-bold"> <font-awesome-icon
            :icon="['fas', 'check']"/> Done</span>
      </template>

      <template #success="{data}">
          <span v-if="data.value === null"></span>
        <span v-if="data.value === false" class="uk-align-center uk-text-center uk-text-danger uk-text-bold"> <font-awesome-icon
            :icon="['fas', 'times']"/></span>
        <span v-if="data.value === true" class="uk-align-center uk-text-center uk-text-success uk-text-bold"> <font-awesome-icon
            :icon="['fas', 'check']"/></span>
      </template>

    </DxDataGrid>

    <DxButton
        v-show="!startedRunning"
        class="run-button-css"
        :width="120"
        hint="Sync Tableau Template Workbooks"
        text="Sync"
        icon="pulldown"
        type="default"
        styling-mode="outlined"
        @click="refreshTemplateWorkbooks"
        :disabled="this.dataSource.length <= 0"
    />

    <span v-show="startedRunning" class="spinner-sync"></span>

  </div>
</template>

<script lang="ts">

import {
  DxDataGrid, DxColumn, DxScrolling, DxToolbar, DxItem, DxRowDragging, DxSelection, DxEditing
} from 'devextreme-vue/data-grid';

import {defineComponent} from "vue";

import {DxButton} from "devextreme-vue";
import CustomStore from "devextreme/data/custom_store";
import {round} from "lodash";
import {TableauApi} from "@/TableauManagement/Api/TableauApi";
import {Toast, ToastOptions, ToastType} from "@/components/Toast";



export default defineComponent({
  name: "TemplateWorkbooksGroupGrid",
  components: {
    DxDataGrid,
    DxColumn,
    DxItem,
    DxScrolling,
    DxToolbar,
    DxButton
  },

  props: {
    selectedWarehouses: new Array<any>(),
    selectedWorkbooks: new Array<any>(),
  },

  data: function () {
    return {
      dataSource: undefined as CustomStore | undefined,
      gridRefName: 'grid',
      startedRunning: false,
    };
  },

  mounted () {
    TableauApi.getSyncLogs().then(response => {
      this.dataSource = response;
    });
  },

  computed: {
    grid(): any {
      return this.$refs[this.gridRefName] as typeof DxDataGrid;
    },
  },

  methods: {
    formatDate(data: any) {
      return new Date(data).toLocaleDateString("en-US", {timeZone: 'America/Chicago'});
    },

    formatTime(data: any) {
      return new Date(data).toLocaleTimeString('en-US', {timeZone: 'America/Chicago'});
    },

    formatTimeDifference(data: any) {
      let time = data.toString();
      let timeArr = time.split(':');

      return timeArr[1] + " Minutes \n" + round(timeArr[2], 0) + " Seconds";
    },

    formatTimeDifferenceFinished(start: any, end: any) {
      let startDate = new Date(start);
      let endDate = new Date(end);

      let dif = Math.abs(startDate.getTime() - endDate.getTime()) / 1000;

      let roundDif = round(dif, 0);

      let minutes = roundDif / 60;

      let seconds = roundDif % 60;

      return round(minutes, 0) + " Minutes \n" + seconds + " Seconds";

    },

    onCellPrepared(e: any) {
      if (e.rowType === 'header') {
        e.cellElement.style.textAlign = 'center';
      }
    },

    refreshTemplateWorkbooks() {
      this.startedRunning = true;
      TableauApi.checkSyncLogs().then(response => {
        if (!response.data) {
          Toast.show(new ToastOptions(`Tableau Sync is already running. Wait.`, ToastType.error));
          this.startedRunning = false;
        } else {
          TableauApi.syncTableauTemplates().then(response => {
            if (response.data) {
              Toast.show(new ToastOptions('This could take a few minutes. Refresh the templates table after some time.', ToastType.success));
              this.startedRunning = false;
              this.refreshTable();
            }

            if (!response.data) {
              Toast.show(new ToastOptions('Something went wrong, or Tableau Sync is still running', ToastType.error));
              this.startedRunning = false;
              this.refreshTable();
            }
          });
        }
      });
    },

    refreshTable() {
      this.grid.instance.refresh();
    },
  }
});
</script>

<style scoped lang="scss">
.run-button-css {
  margin-left: 43%;

}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  margin-left: -2em;

}

.logs {
  margin-left: 25px;
  margin-right: 25px;
  height: 700px;
  width: 1000px;
}

.spinner-sync:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  margin-left: 32em;
}
</style>