<template>
  <div>
    <!--    Modal-->
    <div>
      <default-dialog :is-visible="isVisible" :should-expand="false" @update:isVisible="visibilityChanged">
        <template v-slot:title>
          <h2 class="uk-modal-title">Re-Run Queue</h2>
          <div>Queue: {{ this.reRunGuid }}</div>
        </template>

        <template v-slot>

          <div>
            * Re-run will have a separate queue ID.
          </div>

          <div class="dx-field uk-margin-medium-top">
            <div><b>Options:</b>
              <DxRadioGroup
                  class="uk-margin-small-top"
                  :items="reRunOptions"
                  v-model:value="reRunOptionSelected"
                  layout="horizontal"
              />
            </div>
          </div>
        </template>
        <template v-slot:buttons>
          <loader-component class="uk-margin-remove" v-if="isRunning"/>
          <button v-if="!isRunning" class="uk-button uk-button-primary" @click="ReRunQueue" type="button">Run</button>
          <button v-if="!isRunning" class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
        </template>
      </default-dialog>
    </div>

    <!--    OVERVIEW GRID-->
    <div class="uk-align-center">
      <DxDataGrid
          v-show="selectedQueue === ''"
          class="logs uk-align-center"
          :data-source="queueDataSource"
          :ref="gridRefName2"
          :row-alternation-enabled="true"
          :show-borders="true"
          :column-auto-width="true"
          :allow-column-resizing="true"
          :on-cell-prepared="onCellPrepared"
      >
        <DxScrolling mode="virtual"/>

        <DxColumn
            data-field="isCurrentlyRunning"
            caption="Status"
            cell-template="running"
            :width="110"
            header-cell-template="normal-header"
        />

        <DxColumn
            data-field="runDate"
            caption="Date"
            cell-template="dateTemplate"
            :width="110"
            header-cell-template="date-header"
        />

        <DxColumn
            data-field="runTime"
            caption="Time"
            cell-template="timeTemplate"
            :width="110"
            header-cell-template="time-header"
        />

        <DxColumn
            data-field="queueId"
            caption="Queue ID"
            cell-template="queue"
            :width="300"
            header-cell-template="normal-header"
        />

        <DxColumn
            data-field="failed"
            caption="Workbooks Failed"
            cell-template="number"
            :width="200"
            header-cell-template="failed-header"
        />

        <DxColumn
            data-field="success"
            caption="Workbooks Successful"
            cell-template="number"
            :width="200"
            header-cell-template="successful-header"
        />


        <DxColumn
            data-field="numberOfTotalWorkbooks"
            caption="Workbooks"
            cell-template="number"
            :width="200"
            header-cell-template="book-header"
        />

        <DxColumn
            data-field="numberOfWarehouses"
            caption="Warehouses"
            cell-template="number"
            :width="200"
            header-cell-template="warehouse-header"
        />

        <DxColumn
            data-field="workbooksRemaining"
            caption="Remaining"
            cell-template="leftInQueue"
            :width="140"
            header-cell-template="book-header"
        />

        <DxColumn
            cell-template="re-run-template"
            :width="80"
            header-cell-template="rerun-header"
        />


        <template #queue="{data}">
          <button v-if="data.value !== null" @click="getQueueDetailLogs(data.value)"
                  class="uk-align-center uk-text-center guid-button">{{ data.value }}
          </button>
          <button v-if="data.value === null" class="uk-align-center uk-text-center guid-button-queued">Currently
            Queued
          </button>
        </template>

        <template #number="{data}">
          <sapn class="uk-align-center uk-text-center uk-text-bold">{{ data.value }}</sapn>
        </template>

        <template #leftInQueue="{data}">
          <span v-if="data.value !== null" class="uk-align-center uk-text-bold uk-text-center">{{ data.value }} Left In Queue</span>
          <span v-if="data.value === null" class="uk-align-center uk-text-bold uk-text-center">{{
              data.data.numberOfTotalWorkbooks
            }} Left In Queue</span>
        </template>

        <template #dateTemplate="{data}">
          <span v-if="data.value !== null" class="uk-align-center uk-text-center uk-text-bold">{{
              formatDate(data.value)
            }}</span>
          <span v-if="data.value === null" class="uk-align-center uk-text-center uk-text-bold">Waiting...</span>
        </template>

        <template #timeTemplate="{data}">
          <span v-if="data.value !== null" class="uk-align-center uk-text-center uk-text-bold">{{
              formatTime(data.value)
            }}</span>
          <span v-if="data.value === null" class="uk-align-center uk-text-center uk-text-bold">Waiting...</span>
        </template>

        <template #running="{data}">
          <span v-if="data.value === true" class="spinner"><span
              class="uk-text-bold running-text">Running</span> </span>
          <span v-if="data.value === null" class="uk-align-center uk-text-center uk-text-warning uk-text-bold"> <font-awesome-icon
              :icon="['fas', 'pause']"/> Queued</span>
          <span v-if="data.value === false" class="uk-align-center uk-text-center uk-text-success uk-text-bold"> <font-awesome-icon
              :icon="['fas', 'check']"/> Done</span>
        </template>

        <template #re-run-template="{data}">
          <div>
            <DxButton
                class="uk-align-center"
                :width="80"
                type="default"
                :disabled="data.data.queueId === null || data.data.isCurrentlyRunning"
                icon="play"
                styling-mode="outlined"
                @click="showModal(data)"
            >
              <font-awesome-icon :icon="['fas', 'redo']"/>
            </DxButton>
          </div>
        </template>


        <!--HEADERS-->
        <template #successful-header="{data}">
          <span class="uk-text-bold uk-text-center uk-text-success"> <font-awesome-icon
              :icon="['fas', 'check']"/> {{ data.column.caption }} </span>
        </template>

        <template #book-header="{data}">
          <span class="uk-text-bold uk-text-center"> <font-awesome-icon :icon="['fas', 'book']"/> {{
              data.column.caption
            }} </span>
        </template>

        <template #date-header="{data}">
          <span class="uk-text-bold uk-text-center"> <font-awesome-icon
              :icon="['fas', 'calendar']"/> {{ data.column.caption }} </span>
        </template>

        <template #time-header="{data}">
          <span class="uk-text-bold uk-text-center"> <font-awesome-icon
              :icon="['fas', 'clock']"/> {{ data.column.caption }} </span>
        </template>


        <template #warehouse-header="{data}">
          <span class="uk-text-bold uk-text-center"> <font-awesome-icon
              :icon="['fas', 'warehouse']"/> {{ data.column.caption }} </span>
        </template>


        <template #failed-header="{data}">
          <span class="uk-text-bold uk-text-center uk-text-danger"> <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"/> {{ data.column.caption }} </span>
        </template>

        <template #normal-header="{data}">
          <span class="uk-text-bold uk-text-center"> {{ data.column.caption }} </span>
        </template>

        <template #rerun-header="{}">
          <span class="uk-text-bold uk-text-center"> Re-Run </span>
        </template>

        <DxToolbar>
          <DxItem
              location="after"
              template="add"
          />

        </DxToolbar>

        <template #add>
          <div class="flex-container">
            <DxButton
                :width="170"
                hint="Refresh"
                text="Refresh"
                icon="refresh"
                type="default"
                styling-mode="contained"
                @click="refreshOverviewLogs"
            />
          </div>
        </template>
      </DxDataGrid>
    </div>


    <!--    DETAIL GRID-->
    <div class="uk-align-center">
      <DxDataGrid
          v-if="selectedQueue !== ''"
          class="logs uk-align-center"
          :data-source="dataSource"
          :ref="gridRefName"
          :show-borders="true"
          :column-auto-width="true"
          :allow-column-resizing="true"
          :row-alternation-enabled="true"
      >
        <DxHeaderFilter :visible="true"/>

        <DxScrolling mode="virtual"/>

        <DxColumn
            data-field="currentlyRunning"
            data-type="string"
            caption=""
            cell-template="running"
            :width="30"
        />

        <DxColumn
            data-field="status"
            data-type="string"
            caption="Status"
            :width="125"
            cell-template="statusTemplate"
        />

        <DxColumn
            data-field="queue"
            data-type="string"
            caption="Queue ID"
            :width="300"
            cell-template="queueIdTemplate"
        />

        <DxColumn
            data-field="runDate"
            data-type="string"
            caption="Run Date"
            cell-template="dateTemplate"
            :width="90"
        />

        <DxColumn
            data-field="warehouse"
            data-type="string"
            caption="Warehouse"
            :width="205"
        />

        <DxColumn
            data-field="tableauServer"
            data-type="string"
            caption="Tableau Server"
            :width="205"
        />

        <DxColumn
            data-field="workbook"
            data-type="string"
            caption="Workbook"
            :width="330"
        />

        <DxColumn
            data-field="message"
            data-type="string"
            caption="Message"
            cell-template="messageTemplate"
            :width="450"
        />

        <template #queueIdTemplate="{data}">
          <span>{{ data.value }}</span>
        </template>

        <template #messageTemplate="{data}">
          <span class="message">{{ data.value }}</span>

        </template>

        <template #statusTemplate="{data}">
          <span v-if="data.value === false" class="uk-label-success uk-align-center uk-text-center">Success</span>
          <span v-if="data.value === true" class="uk-label-danger uk-align-center uk-text-center">Failed</span>
        </template>

        <template #queue="{data}">
          <span v-if="data.value !== null" class="uk-align-center uk-text-center">{{ data.value }} Left In Queue</span>
          <span v-if="data.value === null" class="uk-align-center uk-text-center">Queue Done</span>
        </template>

        <template #dateTemplate="{data}">
          <span>{{ formatDate(data.value) }}</span>
        </template>

        <template #running="{data}">
          <span v-if="data.value === 'Currently Running'" class="spinner uk-align-center"></span>
        </template>

        <DxToolbar>
          <DxItem
              location="after"
              template="add"
          />

          <DxItem
              location="before"
              template="back"
          />

        </DxToolbar>

        <template #add>
          <div class="flex-container">
            <DxButton
                :width="170"
                hint="Refresh"
                text="Refresh"
                icon="refresh"
                type="default"
                styling-mode="contained"
                @click="refreshLogs"
            />
          </div>
        </template>

        <template #back>
          <div class="flex-container">
            <DxButton
                :width="120"
                hint="Back To Overview"
                text="Back"
                icon="arrowleft"
                type="primary"
                styling-mode="contained"
                @click="backToOverview"
            />
          </div>
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>

<script lang="ts">

import {
  DxDataGrid, DxColumn, DxScrolling, DxToolbar, DxItem, DxRowDragging, DxSelection, DxEditing
} from 'devextreme-vue/data-grid';

import {defineComponent} from "vue";

import {DxButton, DxRadioGroup} from "devextreme-vue";
import CustomStore from "devextreme/data/custom_store";
import {DxHeaderFilter} from "devextreme-vue/gantt";
import {TableauApi} from "@/TableauManagement/Api/TableauApi";
import {Toast, ToastOptions, ToastType} from "@/components/Toast";
import DefaultDialog from "@/components/DefaultDialog.vue";
import LoaderComponent from "@/components/spinners/LoaderComponent.vue";


export default defineComponent({
  name: "TableauLogs",
  components: {
    DefaultDialog,
    DxDataGrid,
    DxColumn,
    DxItem,
    DxScrolling,
    DxToolbar,
    DxButton,
    DxHeaderFilter,
    DxRadioGroup,
    LoaderComponent
  },

  props: {
    selectedWarehouses: new Array<any>(),
    selectedWorkbooks: new Array<any>(),
  },

  mounted() {
    TableauApi.getTableauQueueLogs().then(response => {
      this.queueDataSource = response;
    });
  },

  data: function () {
    return {
      dataSource: undefined as CustomStore | undefined,
      gridRefName: 'grid',
      gridRefName2: 'grid2',
      startedRunning: false,
      selectedQueue: '',
      queueDataSource: undefined as CustomStore | undefined,
      isVisible: false,
      reRunGuid: '',
      reRunOptions: ['All Workbooks', 'Only Workbooks That Failed'],
      reRunOptionSelected: 'Only Workbooks That Failed',
      isRunning: false
    };
  },

  computed: {
    grid(): any {
      return this.$refs[this.gridRefName] as typeof DxDataGrid;
    },

    grid2(): any {
      return this.$refs[this.gridRefName2] as typeof DxDataGrid;
    },
  },

  methods: {
    refreshLogs() {
      this.grid.instance.refresh();
    },

    backToOverview() {
      this.selectedQueue = '';
    },

    showModal(queueData: any) {
      if (queueData.data.failed === 0) {
        this.reRunOptions = ['All Workbooks'];
        this.reRunOptionSelected = 'All Workbooks';
      }

      if (queueData.data.failed > 0) {
        this.reRunOptions = ['All Workbooks', 'Only Workbooks That Failed'];
        this.reRunOptionSelected = 'Only Workbooks That Failed';
      }

      this.reRunGuid = queueData.data.queueId;
      this.isVisible = true;
    },

    ReRunQueue() {
      this.isRunning = true;
      TableauApi.reRunTableauApi(this.reRunGuid, this.reRunOptionSelected).then(response => {
        this.visibilityChanged();

        this.isRunning = false;

        if (response.data)
          Toast.show(new ToastOptions('Check The Logs To See Queue Progress.', ToastType.success));

        if (!response.data)
          Toast.show(new ToastOptions('Something Went Wrong.', ToastType.error));

        this.refreshOverviewLogs();
      });
    },

    getQueueDetailLogs(queueGuid: any) {
      this.selectedQueue = queueGuid;

      TableauApi.getTableauLogs(this.selectedQueue).then(response => {
        this.dataSource = response;
      });
    },

    onCellPrepared(e: any) {
      if (e.rowType === 'header') {
        e.cellElement.style.textAlign = 'center';
      }
    },

    refreshOverviewLogs() {
      this.grid2.instance.refresh();
    },

    formatDate(data: any) {
      return new Date(data).toLocaleDateString("en-US", {timeZone: 'America/Chicago'});
    },

    formatTime(data: any) {
      return new Date(data).toLocaleTimeString('en-US', {timeZone: 'America/Chicago'});
    },

    visibilityChanged() {
      this.isVisible = false;
      this.reRunGuid = '';
    },
  },

});
</script>

<style scoped lang="scss">
.run-button-css {
  margin-left: 75%;
  margin-top: 10%;
}

.logs {
  margin-left: 25px;
  margin-right: 25px;
  height: 700px;
  width: 1670px;
}


.run-button-css {
  margin-left: 75%;
  margin-top: 10%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  margin-left: -3px;
}

.guid-button {
  padding: 0;
  border: none;
  background: none;
  color: blue;
}

.guid-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.guid-button-queued {
  padding: 0;
  border: none;
  background: none;
  color: black;
}

.header {
  text-align: center !important;
}

.re-run-spinner {
  margin-left: 70px;
}

.running-text {
  margin-left: 20px;
}

.message {
  white-space: initial;
}

.guid-text {
  font-weight: bold;
}
</style>