import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxItem = _resolveComponent("DxItem")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createBlock(_component_DxDataGrid, {
    "data-source": _ctx.dataSource,
    ref: _ctx.gridRefName,
    height: 440,
    width: 250,
    "show-borders": true,
    "column-auto-width": true,
    "allow-column-resizing": true,
    onSelectionChanged: _ctx.onSelectionChanged
  }, {
    button: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DxButton, {
          width: 50,
          icon: "clear",
          hint: "Clear Selection",
          type: "primary",
          "styling-mode": "contained",
          onClick: _ctx.clear
        }, null, 8, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DxScrolling, { mode: "virtual" }),
      _createVNode(_component_DxFilterRow, { visible: true }),
      _createVNode(_component_DxSelection, {
        "show-check-boxes-mode": "always",
        mode: "single",
        "select-all-mode": "allPages"
      }),
      _createVNode(_component_DxColumn, {
        "data-field": "name",
        "data-type": "string",
        caption: "Name"
      }),
      _createVNode(_component_DxToolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_DxItem, { location: "after" }),
          _createVNode(_component_DxItem, {
            location: "after",
            template: "button"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data-source", "onSelectionChanged"]))
}