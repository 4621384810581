import {createApp} from 'vue';
import App from './App.vue';
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";

import 'devextreme/dist/css/dx.light.css';
import '@/styles/global.scss';
import 'uikit/dist/js/uikit.js';
import {Auth0, AuthPlugin} from "@/auth";
import {key, store} from './store';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faArchive,
    faArrowRight,
    faBell,
    faCalendar,
    faCalendarAlt,
    faCaretDown,
    faCaretUp,
    faChartArea,
    faChartLine,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faClipboard,
    faClock,
    faClone,
    faCog,
    faCogs,
    faDatabase,
    faDownload,
    faEllipsisV,
    faEraser,
    faExclamation,
    faExclamationCircle,
    faExclamationTriangle,
    faExpand,
    faExpandArrowsAlt,
    faFastBackward,
    faFile,
    faFileAlt,
    faFilter,
    faFlask,
    faFolder,
    faFolderOpen,
    faGamepad,
    faGlobe,
    faInfoCircle,
    faLock,
    faMapMarkerAlt,
    faPencilAlt,
    faPlayCircle,
    faPlus,
    faPlusCircle,
    faQuestion,
    faQuestionCircle,
    faRocket,
    faSave,
    faSearch,
    faSignOutAlt,
    faStopwatch,
    faSun,
    faSync,
    faSyncAlt,
    faThumbtack,
    faTimes,
    faToggleOff,
    faToggleOn,
    faTrash,
    faTrashAlt,
    faUser,
    faUserAlt,
    faUserPlus,
    faHistory,
    faChartBar,
    faFileInvoice,
    faListAlt,
    faPlusSquare,
    faEdit,
    faEye,
    faPauseCircle,
    faRunning,
    faHatWizard,
    faSortAmountDown,
    faSortAmountUp,
    faUsers,
    faStore,
    faTable,
    faHighlighter,
    faLockOpen,
    faUserEdit,
    faKey,
    faRedo,
    faArrowLeft,
    faMobile,
    faMobileAlt,
    faEyeSlash,
    faWarehouse,
    faBook,
    faPause
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {Event} from "@sentry/types/types/event";
import {GetUserActionPayload} from "./store/modules/Payloads/GetUserActionPayload";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";

library.add(faSortAmountDown, faSortAmountUp, faUsers, faHatWizard, faChevronDown, faHistory, faChartBar, faFileInvoice, faListAlt, faPlusSquare, faDownload, faClock, faRocket, faClipboard, faPlayCircle, faSearch, faLock, faQuestionCircle, faExclamationTriangle, faPlusCircle, faCog, faDatabase, faChartLine,
    faGlobe, faThumbtack, faToggleOff, faToggleOn, faUser, faEllipsisV, faFile, faCalendar, faCheck, faTimes, faEraser, faSave, faTrash, faSun, faCalendar, faCheckCircle, faFolderOpen, faPencilAlt,
    faBell, faSyncAlt, faSync, faClone, faChevronLeft, faArchive, faTrashAlt, faCalendarAlt, faStopwatch, faFastBackward, faMapMarkerAlt, faCaretDown, faUserAlt, faExclamation, faExclamationTriangle,
    faCaretUp, faFlask, faGamepad, faCogs, faChartArea, faGlobe, faFileAlt, faFolder, faUserPlus, faArrowRight, faQuestion, faPlus, faExpand, faExpandArrowsAlt, faSignOutAlt, faChevronUp,
    faExclamationCircle, faInfoCircle, faFilter, faEdit, faEye, faPauseCircle, faRunning, faStore, faTable, faHighlighter, faLockOpen, faUserEdit, faKey, faRedo,faArrowLeft, faMobile, faMobileAlt, faEyeSlash, faWarehouse, faBook, faPause,
    faEnvelope
);

async function init() {
    const app = createApp(App);

    Sentry.init({
        app,
        environment: process.env.NODE_ENV,
        logErrors: true,
        dsn: "https://326eed55af6e4fccb0d0fbef38f08b21@o1113758.ingest.sentry.io/6144528",
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ["localhost", "dsci.com", "engine.net", /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: .2,

        beforeSend(event: Event) {
            if (process.env.NODE_ENV == 'development') {
                return null;
            }

            // Auth0 will throw this when redirecting to login page.
            // Not really an error as it gets resolved in code.
            if (event.message === 'Login required')
                return null;

            return event;
        }
    });

    const authPlugin = await Auth0.init({
        clientId: process.env.VUE_APP_AUTH0_CLIENTID,
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        redirectUri: window.location.origin,
    });

    const {isAuthenticated} = AuthPlugin;
    if (isAuthenticated.value) {
        await store.dispatch(new GetUserActionPayload());

        Sentry.setUser({
            id: store.state.user.user_id,
            username: store.state.user.name
        });
    }

        app
            .use(authPlugin)
            .use(store, key)
            .component("font-awesome-icon", FontAwesomeIcon)
            .mount('#app');
}

init();
