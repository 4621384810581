import {InjectionKey} from 'vue';
import {createStore, Store, useStore as baseUseStore} from 'vuex';
import UserModule, {IUserState} from "@/store/modules/user.module";

// define your typings for the store state
export interface GlobalState {
    user: IUserState
}

// define injection key
export const key: InjectionKey<Store<GlobalState>> = Symbol();

export const store = createStore<GlobalState>({
    modules: {
        user: UserModule
    }
});

// define your own `useStore` composition function
export function useStore(): Store<GlobalState> {
    return baseUseStore(key);
}