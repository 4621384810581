<template>
  <div v-if="dataSource !== undefined">

    <default-dialog class="modal" :is-visible="isVisible" :should-expand="false" @update:isVisible="visibilityChanged">
      <template v-slot:title>
        <h2 class="uk-modal-title">Save selected workbooks as a group</h2>
      </template>

      <template v-slot>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Group Name</div>
            <div class="dx-field-value">
              <DxTextBox v-model:value="groupName"/>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:buttons>
        <loader-component class="uk-margin-remove" v-if="isRunning"/>
        <button v-if="!isRunning" class="uk-button uk-button-primary" @click="saveGroup" type="button">Save</button>
        <button v-if="!isRunning" class="uk-button uk-button-default uk-modal-close" type="button">Cancel</button>
      </template>
    </default-dialog>

    <DxDataGrid
        :data-source="dataSource"
        :ref="gridRefName"
        :height="440"
        :width="600"
        key-expr="id"
        :show-borders="true"
        :allow-column-resizing="true"
        @selection-changed="onSelectionChanged"
    >
      <DxFilterRow :visible="true"/>

      <DxScrolling mode="virtual"/>

      <DxColumn
          data-field="name"
          data-type="string"
      />

      <DxColumn
          data-field="site.name"
          caption="Site"
          data-type="string"
      />

      <DxColumn
          data-field="project.name"
          caption="Folder"
          width="100"
      />

      <DxSelection
          show-check-boxes-mode="always"
          mode="multiple"
      />

      <DxToolbar>

        <DxItem
            location="after"
            template="templateSelection"
        />

        <DxItem
            location="after"
            template="refreshbutton"
        />

        <DxItem
            location="after"
            template="groupingTemplate"
        />


      </DxToolbar>

      <template #groupingTemplate>
        <div class="flex-container">
          <DxButton
              :width="50"
              hint="Clear Selection"
              icon="clear"
              type="primary"
              styling-mode="contained"
              @click="clear"
          />
        </div>
      </template>


      <template #refreshbutton>
        <div class="flex-container">
          <DxButton
              :width="50"
              hint="Refresh Data Grid"
              icon="refresh"
              type="primary"
              styling-mode="outlined"
              @click="refresh"
          />
        </div>
      </template>

      <template #templateSelection>
        <div class="flex-container">
          <DxSelectBox :width="200"
                       :items="groups"
                       display-expr="name"
                       value-expr="id"
                       @value-changed="groupChanged"/>
        </div>
      </template>

    </DxDataGrid>

    <DxButton
        v-show="!startedRunning"
        class="run-button-css"
        :width="120"
        hint="Save Selected Workbooks As A Group"
        text="Save"
        icon="save"
        type="default"
        styling-mode="outlined"
        @click="saveGroupPopUp"
        :disabled="this.selectedRowsData.length <= 0"
    />

    <span v-show="startedRunning" class="spinner-sync"></span>

  </div>
</template>

<script lang="ts">

import {
  DxDataGrid, DxColumn, DxRowDragging, DxScrolling, DxSelection, DxFilterRow, DxToolbar, DxItem
} from 'devextreme-vue/data-grid';
import {defineComponent} from "vue";
import CustomStore from "devextreme/data/custom_store";
import {DxButton, DxTextBox} from "devextreme-vue";


import DxSelectBox from "devextreme-vue/select-box";
import {TemplateGroups} from "@/TableauManagement/Api/Entities/TemplateGroups";
import {TableauApi} from "@/TableauManagement/Api/TableauApi";
import {Toast, ToastOptions, ToastType} from "@/components/Toast";
import DefaultDialog from "@/components/DefaultDialog.vue";
import LoaderComponent from "@/components/spinners/LoaderComponent.vue";


export default defineComponent({
  name: "TemplateWorkbooksGrid",
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSelection,
    DxFilterRow,
    DxToolbar,
    DxButton,
    DxItem,
    DxSelectBox,
    DefaultDialog,
    LoaderComponent,
    DxTextBox,

  },

  data: function () {
    return {
      dataSource: undefined as CustomStore | undefined,
      selectedRowsData: new Array<any>(),
      gridRefName: 'grid',
      startedRunning: false,
      isSyncRunning: false,
      timeStarted: 0,
      isVisible: false,
      groups: new Array<TemplateGroups>(),
      isRunning: false,
      groupName: ''
    };
  },

  computed: {
    grid(): any {
      return this.$refs[this.gridRefName] as typeof DxDataGrid;
    },
  },

  emits: ['saveWorkbooks'],

  mounted: async function () {
    TableauApi.getTemplateWorkbooks().then(result => {
      this.dataSource = result;
    });

    TableauApi.templateGroupsDataSource().then(response => {
      this.groups = response.data;
    });
  },

  methods: {
    onSelectionChanged(selectedRows: any) {
      this.selectedRowsData = new Array<any>();

      for (let x = 0; x < selectedRows.selectedRowsData.length; x++) {
        this.selectedRowsData.push(selectedRows.selectedRowsData[x]);
      }

      this.$emit('saveWorkbooks', this.selectedRowsData);
    },

    saveGroupPopUp() {
      this.isVisible = true;
    },

    visibilityChanged() {
      this.isVisible = false;
    },

    groupChanged(group: any) {
      TableauApi.selectedGroupWorkbooks(group.value).then(response => {
        this.grid.instance.selectRows(response.data);
      });
    },

    clear() {
      this.selectedRowsData = new Array<any>();
      this.grid.instance.deselectAll();
    },

    saveGroup() {
      let workbook_list = new Array<string>();
      for (let w = 0; w < this.selectedRowsData.length; w++) {
        workbook_list.push(this.selectedRowsData[w].id);
      }

      let was_success = false;
      TableauApi.saveNewTemplateGroup(this.groupName, workbook_list).then(response => {
        was_success = response.data;

        if (was_success) {
          TableauApi.templateGroupsDataSource().then(response => {
            this.groups = response.data;
          });

          this.isVisible = false;
          Toast.show(new ToastOptions('Template Group Has Been Saved.', ToastType.success));
        }
      });
    },

    refresh() {
      this.grid.instance.refresh();
    },

  },

})
;
</script>

<style scoped lang="scss">
.run-button-css {
  margin-left: 75%;
  margin-top: 10%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  margin-left: 15em;
  margin-top: 2em;
}

.spinner-sync:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #000;
  animation: spinner .6s linear infinite;
  margin-left: 17.5em;
  margin-top: 2.5em;
}

.modal {
  z-index: 9999999999999;
}
</style>